import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgbDropdownModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { Observable, of, take } from 'rxjs';

import moment from 'moment';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';

interface IOptionListItem {
  name: string;
  _id: any;
}

export interface IFilterOption {
  name: string; // Title of the control
  id: any; // id in output params
  type: 'text' | 'date' | 'select' | 'multiselect'; // type of control
  options?: Observable<IOptionListItem[]>; // option list in case of select/multiselect to choose from
  values?: any;
  label?: string;
  default?: any;
  hasDependent?: boolean;
  dependent?: string | number; // Id of the dependent
}

interface IRange {
  value: Date[];
  label: string;
}

@Component({
  selector: 'app-filter',
  imports: [
    CommonModule,
    BsDatepickerModule,
    MultiSelectModule,
    FormsModule,
    BsDropdownModule,
    NgbTooltipModule,
    NgbDropdownModule,
  ],
  templateUrl: './filter.component.html',
})
export class FilterComponent {
  @Input() options: IFilterOption[] = [];
  @Input() showSearch: boolean = true;
  @Input() showPDF: boolean = false;
  @Input() showCSV: boolean = false;
  @Input() showEdit?: boolean = false;
  @Input() showFiles?: boolean = false;
  @Input() showResetFilterOption: boolean = true;
  @Input() viewChanger: 'co2' | 'water' | 'none' = 'none';

  @Output() filterOutput = new EventEmitter<Object>();
  @Output() downloadPDF = new EventEmitter<any>();
  @Output() openEditAsset = new EventEmitter<any>();
  @Output() openReplaceDevice = new EventEmitter<any>();
  @Output() openFiles = new EventEmitter<any>();
  @Output() exportCSV = new EventEmitter<any>();
  @Output() viewChangeHandler = new EventEmitter<any>();

  dateRange: IRange[] = [
    {
      value: [new Date(new Date().setDate(new Date().getDate())), new Date()],
      label: 'Today',
    },
    {
      value: [
        moment().startOf('isoWeek').toDate(),
        moment().endOf('isoWeek').toDate(),
      ],
      label: 'This Week',
    },
    {
      value: [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date(),
      ],
      label: 'This Month',
    },
    {
      value: [
        new Date(new Date().setDate(new Date().getDate() - 7)),
        new Date(),
      ],
      label: 'Last 7 Days',
    },
    {
      value: [
        new Date(new Date().setDate(new Date().getDate() - 14)),
        new Date(),
      ],
      label: 'Last 14 Days',
    },
    {
      value: [
        new Date(new Date().setDate(new Date().getDate() - 28)),
        new Date(),
      ],
      label: 'Last 28 Days',
    },
  ];
  maxDate = new Date();
  anyOption = of({ name: 'Any', value: '' });

  model: any = {};
  params: any = {};
  currentUser: User;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthenticationService
  ) {
    this.auth.currentAccountValue.subscribe(x => {
      if (x) {
        this.currentUser = x;
      }
    });
    this.activatedRoute.queryParams.subscribe(params => {
      // console.log('filter param:', params, this.model);
      if (params) {
        this.params = params ?? {};
        if (this.params.timeRange) {
          let dateObj = JSON.parse(this.params.timeRange);
          this.params = {
            ...this.params,
            timeRange: [
              new Date(new Date(dateObj?.start).setHours(0, 0, 0, 0)),
              new Date(new Date(dateObj?.end).setHours(23, 59, 59, 999)),
            ],
          };
        }
        // console.log('this.params:', this.params);
        this.model = { ...this.params };
      }
    });
  }

  checkUnappliedFilter(): boolean {
    return JSON.stringify(this.model) !== JSON.stringify(this.params);
  }

  redirectToCO2App() {
    window.open(
      `https://daa.apps.semvar.com/#/buildings/6489fb6228855c2c1a438e56?siteId=6489fb6128855c2c1a438d86&buildingId=6489fb6228855c2c1a438e56&timeRange={"start":"${moment()
        .startOf('day')
        .subtract(7, 'days')
        .toISOString()}","end":"${moment()
        .endOf('day')
        .toISOString()}"}&token=${this.currentUser.token}`,
      '_blank'
    );
  }

  showResetFilter() {
    if (this.params) {
      let logs = [];
      for (let key in this.params) {
        this.params[key] !== this.params[key].default;
        if (this.params['search']) logs.push(true);

        if (
          this.params[key] &&
          this.params[key].default &&
          this.params[key] !== this.params[key].default
        ) {
          logs.push(true);
        }

        this.options?.forEach(o => {
          if (
            this.params[key] &&
            o.id === key &&
            o?.default === this.params[key]
          )
            logs.push(false);
          if (
            this.params[key] &&
            o.id === key &&
            o?.default !== this.params[key]
          ) {
            logs.push(true);
          }
        });
      }
      // console.log('logs:', logs);
      return logs.indexOf(true) > -1 ? true : false;
    } else {
      return false;
    }
  }

  onFilter() {
    let data = { ...this.model };
    if (data) {
      for (let key in data) {
        this.options?.forEach(o => {
          if (
            data[key] &&
            o.id === key &&
            o?.type === 'date' &&
            typeof data[key] === 'object'
          ) {
            // console.log(data[key], typeof data[key]);
            data[key] = JSON.stringify({
              start: new Date(data[key][0].setHours(0, 0, 0, 0))?.toISOString(),
              end: new Date(
                data[key][1].setHours(23, 59, 59, 999)
              )?.toISOString(),
            });
          }
        });
      }
    }
    console.log('this.model:', data);

    this.filterOutput.emit(data);
  }

  resetFilter() {
    this.model = {};
    this.onFilter();
  }

  clearDropdown(item) {
    this.model[item.id] = '';
    if (!item.hasDependent) {
      this.onFilter();
    }
  }

  dropdownChange(selection, item) {
    if (selection && item) {
      this.model[item.id] = selection?._id;
      this.filterOutput.emit(item.id + this.model[item.id]);
      // console.log('selection.hasDependent:', item);
      if (!item.hasDependent) {
        this.onFilter();
      } else if (item.hasDependent && item.dependent) {
        const dependentItem = this.options.filter(
          i => i.id === item.dependent
        )[0];
        // take(2) because 1 the options list will be empty then 2 it will be filled with new data
        dependentItem?.options?.pipe(take(2)).subscribe(options => {
          console.log('options:', options);
          if (options) {
            const complete_item_obj = this.options.find(
              o => o.id === item.dependent
            );
            // Default select first item
            let sortedOptions = [];
            // If asset list then sort is first by floor then select the first one
            // otherwise select the first item without any sort order
            if (item.dependent === 'assetId') {
              sortedOptions = this.getSortedOption(options);
            } else {
              sortedOptions = options;
            }
            this.dropdownChange(sortedOptions[0], complete_item_obj);
          }
        });
      }
    }
  }

  getName(subject: any, id: number | string) {
    let list = subject?.getValue();
    if (list && typeof list === 'object' && list?.length > 0) {
      let name = list.filter(x => x?._id === id);
      //  Return if any selection is made otherwise show the label
      return name[0]?.name || undefined;
    }
  }

  isTypeDate(item: IFilterOption) {
    if (item.type === 'date') {
      // if (item.default) {
      //   this.model[item.id] = item.default;
      // }
      return true;
    } else {
      false;
    }
  }

  getValue(item) {
    return item._id;
  }

  downloadHandler() {
    this.downloadPDF.emit(true);
  }

  // For Asset Report
  openEdit() {
    this.openEditAsset.emit(true);
  }

  openReplace() {
    this.openReplaceDevice.emit(true);
  }

  // Building Report
  openAttachments() {
    this.openFiles.emit(true);
  }

  getSortedOption(options: any[]) {
    const sorted = options?.sort((a, b) => {
      const floorA =
        a.attributes.floor !== undefined ? a.attributes.floor : -Infinity;
      const floorB =
        b.attributes.floor !== undefined ? b.attributes.floor : -Infinity;

      if (a.attributes.floor === undefined && b.attributes.floor < 0) return 1;
      if (b.attributes.floor === undefined && a.attributes.floor < 0) return -1;

      return floorB - floorA;
    });
    return sorted;
  }
}
