<div class="row mx-1 mx-md-2">
  <div class="mr-1 mr-md-4 mb-3" *ngIf="showSearch">
    <div class="">
      <div class="h6 text-muted surtitle mb-1">Search</div>
      <input
        type="text"
        id="search"
        class="form-control"
        placeholder="Press enter to search..."
        [style.width]="'240px'"
        [(ngModel)]="model['search']"
        (keyup.enter)="onFilter()"
        name="model['search']" />
    </div>
  </div>
  <ng-container *ngIf="options">
    <ng-container *ngFor="let item of options">
      <!-- Text  -->
      <ng-template [ngIf]="item.type === 'text'">
        <div class="mr-1 mr-md-4 mb-3">
          <div class="">
            <div class="h6 text-muted surtitle mb-1">{{ item?.name }}</div>
            <input
              type="text"
              [id]="item.id"
              class="form-control"
              [placeholder]="item?.name"
              [(ngModel)]="model[item.id]" />
          </div>
        </div>
      </ng-template>
      <!-- Select -->
      <ng-template [ngIf]="item.type === 'select'">
        <div class="mr-1 mr-md-4 mb-3">
          <div class="h6 text-muted surtitle mb-1">{{ item?.name }}</div>
          <div class="btn-group dropdown" dropdown id="{{ item?.name }}">
            <button
              type="button"
              class="btn dropdown-toggle"
              [ngClass]="
                getName(item.values, model[item.id])
                  ? 'btn-primary'
                  : 'btn-outline-primary'
              "
              dropdownToggle>
              {{ getName(item.values, model[item.id]) || item?.label }}
            </button>
            <div *dropdownMenu class="dropdown-menu">
              <a class="dropdown-item" (click)="clearDropdown(item)">
                {{ item?.label }}
              </a>
              <div class="dropdown-divider"></div>
              <ng-container *ngIf="item.id === 'assetId'">
                <span *ngFor="let i of getSortedOption(item.options | async)">
                  <a class="dropdown-item" (click)="dropdownChange(i, item)">
                    {{ i.name }}
                  </a>
                </span>
              </ng-container>
              <ng-container *ngIf="item.id !== 'assetId'">
                <span *ngFor="let i of item.options | async">
                  <a class="dropdown-item" (click)="dropdownChange(i, item)">
                    {{ i.name }}
                  </a>
                </span>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Date -->
      <ng-container *ngIf="isTypeDate(item)">
        <div class="mr-1 mr-md-4 mb-3">
          <div class="">
            <div class="h6 text-muted surtitle mb-1">{{ item?.name }}</div>
            <input
              autocomplete="off"
              type="text"
              class="form-control"
              bsDaterangepicker
              [(ngModel)]="model[item.id]"
              (ngModelChange)="onFilter()"
              [style.width]="'210px'"
              [bsConfig]="{
                isAnimated: true,
                ranges: dateRange,
                containerClass: 'theme-dark-blue',
                rangeInputFormat: 'DD MMM YYYY'
              }"
              placement="bottom left"
              name="bsDaterangepicker"
              placeholder="01/09/2024 - 08/09/2024"
              [maxDate]="maxDate" />
          </div>
        </div>
      </ng-container>
      <!-- MultiSelect -->
      <ng-template [ngIf]="item.type === 'multiselect'">
        <div class="mr-1 mr-md-4 mb-3">
          <div class="">
            <div class="h6 text-muted surtitle mb-1">{{ item?.name }}</div>
            <p-multiSelect
              [options]="item.options | async"
              [(ngModel)]="model[item.id]"
              optionLabel="name"
              optionValue="value"></p-multiSelect>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
  <div class="flex-grow-1"></div>
  <div class="not-for-print">
    <div class="h6 text-muted surtitle mb-1">&nbsp;</div>
    <button
      *ngIf="viewChanger !== 'none'"
      ngbTooltip="Switch to {{ viewChanger === 'co2' ? 'Water' : 'CO2' }} View"
      container="body"
      class="btn btn-success float-right mr-2"
      type="button"
      (click)="redirectToCO2App()">
      <i class="fa-solid fa-fan" *ngIf="viewChanger === 'water'"></i>
      <i class="fa-solid fa-faucet" *ngIf="viewChanger === 'co2'"></i>
    </button>

    <button
      *ngIf="showResetFilter() && showResetFilterOption"
      class="btn btn-outline-secondary"
      (click)="resetFilter()">
      <i class="fas fa-undo-alt"></i>
      Clear Filter
    </button>
    <button
      *ngIf="showEdit"
      ngbTooltip="Edit Asset"
      name="editAsset"
      container="body"
      class="btn btn-warning float-right ml-2"
      type="button"
      data-testid="editAssetButton"
      (click)="openEdit()">
      <i class="fas fa-pencil"></i>
    </button>
    <button
      *ngIf="showFiles"
      ngbTooltip="Files"
      container="body"
      class="btn btn-primary float-right ml-2 d-none d-md-inline"
      type="button"
      (click)="openAttachments()">
      <i class="fas fa-folder-open"></i>
    </button>
    <button
      *ngIf="showPDF"
      ngbTooltip="Download Report"
      container="body"
      class="btn btn-info float-right ml-2 d-none d-md-inline"
      type="button"
      (click)="downloadHandler()">
      <i class="fas fa-download"></i>
    </button>
    <button
      *ngIf="showCSV"
      ngbTooltip="Export to CSV"
      container="body"
      class="btn btn-success float-right mr-2 d-none d-md-inline"
      type="button"
      (click)="exportCSV.emit(true)">
      <i class="fa-solid fa-file-csv"></i>
    </button>
  </div>
</div>
