import * as i0 from '@angular/core';
import { Injectable, Component, ChangeDetectionStrategy, EventEmitter, Directive, Input, Output, NgModule } from '@angular/core';
import { getBsVer, warnOnce, parseTriggers, OnChange } from 'ngx-bootstrap/utils';
import * as i3 from 'ngx-bootstrap/positioning';
import { PlacementForBs5, PositioningService } from 'ngx-bootstrap/positioning';
import { __decorate, __metadata } from 'tslib';
import * as i1 from 'ngx-bootstrap/component-loader';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { timer } from 'rxjs';
import { CommonModule } from '@angular/common';

/** Default values provider for tooltip */
class TooltipConfig {
  constructor() {
    /** sets disable adaptive position */
    this.adaptivePosition = true;
    /** tooltip placement, supported positions: 'top', 'bottom', 'left', 'right' */
    this.placement = 'top';
    /** array of event names which triggers tooltip opening */
    this.triggers = 'hover focus';
    /** delay before showing the tooltip */
    this.delay = 0;
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "19.0.1",
      ngImport: i0,
      type: TooltipConfig,
      deps: [],
      target: i0.ɵɵFactoryTarget.Injectable
    });
  }
  static {
    this.ɵprov = i0.ɵɵngDeclareInjectable({
      minVersion: "12.0.0",
      version: "19.0.1",
      ngImport: i0,
      type: TooltipConfig,
      providedIn: 'root'
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.0.1",
  ngImport: i0,
  type: TooltipConfig,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }]
});
class TooltipContainerComponent {
  get _bsVersions() {
    return getBsVer();
  }
  constructor(config) {
    Object.assign(this, config);
  }
  ngAfterViewInit() {
    this.classMap = {
      in: false,
      fade: false
    };
    if (this.placement) {
      if (this._bsVersions.isBs5) {
        this.placement = PlacementForBs5[this.placement];
      }
      this.classMap[this.placement] = true;
    }
    this.classMap[`tooltip-${this.placement}`] = true;
    this.classMap["in"] = true;
    if (this.animation) {
      this.classMap["fade"] = true;
    }
    if (this.containerClass) {
      this.classMap[this.containerClass] = true;
    }
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "19.0.1",
      ngImport: i0,
      type: TooltipContainerComponent,
      deps: [{
        token: TooltipConfig
      }],
      target: i0.ɵɵFactoryTarget.Component
    });
  }
  static {
    this.ɵcmp = i0.ɵɵngDeclareComponent({
      minVersion: "14.0.0",
      version: "19.0.1",
      type: TooltipContainerComponent,
      isStandalone: true,
      selector: "bs-tooltip-container",
      host: {
        attributes: {
          "role": "tooltip"
        },
        properties: {
          "class": "\"show tooltip in tooltip-\" + placement + \" \" + \"bs-tooltip-\" + placement + \" \" + placement + \" \" + containerClass",
          "attr.id": "this.id"
        }
      },
      ngImport: i0,
      template: `
    <div class="tooltip-arrow arrow"></div>
    <div class="tooltip-inner"><ng-content></ng-content></div>
    `,
      isInline: true,
      styles: [":host.tooltip{display:block;pointer-events:none;position:absolute}:host.tooltip .tooltip-arrow{position:absolute}\n"],
      changeDetection: i0.ChangeDetectionStrategy.OnPush
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.0.1",
  ngImport: i0,
  type: TooltipContainerComponent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'bs-tooltip-container',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[class]': '"show tooltip in tooltip-" + placement + " " + "bs-tooltip-" + placement + " " + placement + " " + containerClass',
        '[attr.id]': 'this.id',
        role: 'tooltip'
      },
      template: `
    <div class="tooltip-arrow arrow"></div>
    <div class="tooltip-inner"><ng-content></ng-content></div>
    `,
      standalone: true,
      styles: [":host.tooltip{display:block;pointer-events:none;position:absolute}:host.tooltip .tooltip-arrow{position:absolute}\n"]
    }]
  }],
  ctorParameters: () => [{
    type: TooltipConfig
  }]
});
let id = 0;
class TooltipDirective {
  /**
   * Returns whether or not the tooltip is currently being shown
   */
  get isOpen() {
    return this._tooltip.isShown;
  }
  set isOpen(value) {
    if (value) {
      this.show();
    } else {
      this.hide();
    }
  }
  /** @deprecated - please use `tooltip` instead */
  set htmlContent(value) {
    warnOnce('tooltipHtml was deprecated, please use `tooltip` instead');
    this.tooltip = value;
  }
  /** @deprecated - please use `placement` instead */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  set _placement(value) {
    warnOnce('tooltipPlacement was deprecated, please use `placement` instead');
    this.placement = value;
  }
  /** @deprecated - please use `isOpen` instead */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  set _isOpen(value) {
    warnOnce('tooltipIsOpen was deprecated, please use `isOpen` instead');
    this.isOpen = value;
  }
  get _isOpen() {
    warnOnce('tooltipIsOpen was deprecated, please use `isOpen` instead');
    return this.isOpen;
  }
  /** @deprecated - please use `isDisabled` instead */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  set _enable(value) {
    warnOnce('tooltipEnable was deprecated, please use `isDisabled` instead');
    this.isDisabled = !value;
  }
  get _enable() {
    warnOnce('tooltipEnable was deprecated, please use `isDisabled` instead');
    return this.isDisabled;
  }
  /** @deprecated - please use `container="body"` instead */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  set _appendToBody(value) {
    warnOnce('tooltipAppendToBody was deprecated, please use `container="body"` instead');
    this.container = value ? 'body' : this.container;
  }
  get _appendToBody() {
    warnOnce('tooltipAppendToBody was deprecated, please use `container="body"` instead');
    return this.container === 'body';
  }
  /** @deprecated - will replaced with customClass */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  set _popupClass(value) {
    warnOnce('tooltipClass deprecated');
  }
  /** @deprecated - removed */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  set _tooltipContext(value) {
    warnOnce('tooltipContext deprecated');
  }
  /** @deprecated */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  set _tooltipPopupDelay(value) {
    warnOnce('tooltipPopupDelay is deprecated, use `delay` instead');
    this.delay = value;
  }
  /** @deprecated -  please use `triggers` instead */
  get _tooltipTrigger() {
    warnOnce('tooltipTrigger was deprecated, please use `triggers` instead');
    return this.triggers;
  }
  set _tooltipTrigger(value) {
    warnOnce('tooltipTrigger was deprecated, please use `triggers` instead');
    this.triggers = (value || '').toString();
  }
  constructor(_viewContainerRef, cis, config, _elementRef, _renderer, _positionService) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
    this._positionService = _positionService;
    this.tooltipId = id++;
    /** sets disable adaptive position */
    this.adaptivePosition = true;
    /** Fired when tooltip content changes */
    this.tooltipChange = new EventEmitter();
    /**
     * Placement of a tooltip. Accepts: "top", "bottom", "left", "right"
     */
    this.placement = 'top';
    /**
     * Specifies events that should trigger. Supports a space separated list of
     * event names.
     */
    this.triggers = 'hover focus';
    /**
     * Css class for tooltip container
     */
    this.containerClass = '';
    /**
     * Allows to disable tooltip
     */
    this.isDisabled = false;
    /**
     * Delay before showing the tooltip
     */
    this.delay = 0;
    /** @deprecated - removed, will be added to configuration */
    this.tooltipAnimation = true;
    /** @deprecated */
    this.tooltipFadeDuration = 150;
    /** @deprecated */
    this.tooltipStateChanged = new EventEmitter();
    this._tooltip = cis.createLoader(this._elementRef, _viewContainerRef, this._renderer).provide({
      provide: TooltipConfig,
      useValue: config
    });
    Object.assign(this, config);
    this.onShown = this._tooltip.onShown;
    this.onHidden = this._tooltip.onHidden;
  }
  ngOnInit() {
    this._tooltip.listen({
      triggers: this.triggers,
      show: () => this.show()
    });
    this.tooltipChange.subscribe(value => {
      if (!value) {
        this._tooltip.hide();
      }
    });
    this.onShown.subscribe(() => {
      this.setAriaDescribedBy();
    });
    this.onHidden.subscribe(() => {
      this.setAriaDescribedBy();
    });
  }
  setAriaDescribedBy() {
    this._ariaDescribedby = this.isOpen ? `tooltip-${this.tooltipId}` : void 0;
    if (this._ariaDescribedby) {
      this._renderer.setAttribute(this._elementRef.nativeElement, 'aria-describedby', this._ariaDescribedby);
    } else {
      this._renderer.removeAttribute(this._elementRef.nativeElement, 'aria-describedby');
    }
  }
  /**
   * Toggles an element’s tooltip. This is considered a “manual” triggering of
   * the tooltip.
   */
  toggle() {
    if (this.isOpen) {
      return this.hide();
    }
    this.show();
  }
  /**
   * Opens an element’s tooltip. This is considered a “manual” triggering of
   * the tooltip.
   */
  show() {
    this._positionService.setOptions({
      modifiers: {
        flip: {
          enabled: this.adaptivePosition
        },
        preventOverflow: {
          enabled: this.adaptivePosition,
          boundariesElement: this.boundariesElement || 'scrollParent'
        }
      }
    });
    if (this.isOpen || this.isDisabled || this._delayTimeoutId || !this.tooltip) {
      return;
    }
    const showTooltip = () => {
      if (this._delayTimeoutId) {
        this._delayTimeoutId = undefined;
      }
      this._tooltip.attach(TooltipContainerComponent).to(this.container).position({
        attachment: this.placement
      }).show({
        content: this.tooltip,
        placement: this.placement,
        containerClass: this.containerClass,
        id: `tooltip-${this.tooltipId}`
      });
    };
    const cancelDelayedTooltipShowing = () => {
      if (this._tooltipCancelShowFn) {
        this._tooltipCancelShowFn();
      }
    };
    if (this.delay) {
      if (this._delaySubscription) {
        this._delaySubscription.unsubscribe();
      }
      this._delaySubscription = timer(this.delay).subscribe(() => {
        showTooltip();
        cancelDelayedTooltipShowing();
      });
      if (this.triggers) {
        parseTriggers(this.triggers).forEach(trigger => {
          if (!trigger.close) {
            return;
          }
          this._tooltipCancelShowFn = this._renderer.listen(this._elementRef.nativeElement, trigger.close, () => {
            this._delaySubscription?.unsubscribe();
            cancelDelayedTooltipShowing();
          });
        });
      }
    } else {
      showTooltip();
    }
  }
  /**
   * Closes an element’s tooltip. This is considered a “manual” triggering of
   * the tooltip.
   */
  hide() {
    if (this._delayTimeoutId) {
      clearTimeout(this._delayTimeoutId);
      this._delayTimeoutId = undefined;
    }
    if (!this._tooltip.isShown) {
      return;
    }
    if (this._tooltip.instance?.classMap) {
      this._tooltip.instance.classMap['in'] = false;
    }
    setTimeout(() => {
      this._tooltip.hide();
    }, this.tooltipFadeDuration);
  }
  ngOnDestroy() {
    this._tooltip.dispose();
    this.tooltipChange.unsubscribe();
    if (this._delaySubscription) {
      this._delaySubscription.unsubscribe();
    }
    this.onShown.unsubscribe();
    this.onHidden.unsubscribe();
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "19.0.1",
      ngImport: i0,
      type: TooltipDirective,
      deps: [{
        token: i0.ViewContainerRef
      }, {
        token: i1.ComponentLoaderFactory
      }, {
        token: TooltipConfig
      }, {
        token: i0.ElementRef
      }, {
        token: i0.Renderer2
      }, {
        token: i3.PositioningService
      }],
      target: i0.ɵɵFactoryTarget.Directive
    });
  }
  static {
    this.ɵdir = i0.ɵɵngDeclareDirective({
      minVersion: "14.0.0",
      version: "19.0.1",
      type: TooltipDirective,
      isStandalone: true,
      selector: "[tooltip], [tooltipHtml]",
      inputs: {
        adaptivePosition: "adaptivePosition",
        tooltip: "tooltip",
        placement: "placement",
        triggers: "triggers",
        container: "container",
        containerClass: "containerClass",
        boundariesElement: "boundariesElement",
        isOpen: "isOpen",
        isDisabled: "isDisabled",
        delay: "delay",
        htmlContent: ["tooltipHtml", "htmlContent"],
        _placement: ["tooltipPlacement", "_placement"],
        _isOpen: ["tooltipIsOpen", "_isOpen"],
        _enable: ["tooltipEnable", "_enable"],
        _appendToBody: ["tooltipAppendToBody", "_appendToBody"],
        tooltipAnimation: "tooltipAnimation",
        _popupClass: ["tooltipClass", "_popupClass"],
        _tooltipContext: ["tooltipContext", "_tooltipContext"],
        _tooltipPopupDelay: ["tooltipPopupDelay", "_tooltipPopupDelay"],
        tooltipFadeDuration: "tooltipFadeDuration",
        _tooltipTrigger: ["tooltipTrigger", "_tooltipTrigger"]
      },
      outputs: {
        tooltipChange: "tooltipChange",
        onShown: "onShown",
        onHidden: "onHidden",
        tooltipStateChanged: "tooltipStateChanged"
      },
      providers: [ComponentLoaderFactory, PositioningService],
      exportAs: ["bs-tooltip"],
      ngImport: i0
    });
  }
}
__decorate([OnChange(), __metadata("design:type", Object)], TooltipDirective.prototype, "tooltip", void 0);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.0.1",
  ngImport: i0,
  type: TooltipDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: '[tooltip], [tooltipHtml]',
      exportAs: 'bs-tooltip',
      standalone: true,
      providers: [ComponentLoaderFactory, PositioningService]
    }]
  }],
  ctorParameters: () => [{
    type: i0.ViewContainerRef
  }, {
    type: i1.ComponentLoaderFactory
  }, {
    type: TooltipConfig
  }, {
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: i3.PositioningService
  }],
  propDecorators: {
    adaptivePosition: [{
      type: Input
    }],
    tooltip: [{
      type: Input
    }],
    tooltipChange: [{
      type: Output
    }],
    placement: [{
      type: Input
    }],
    triggers: [{
      type: Input
    }],
    container: [{
      type: Input
    }],
    containerClass: [{
      type: Input
    }],
    boundariesElement: [{
      type: Input
    }],
    isOpen: [{
      type: Input
    }],
    isDisabled: [{
      type: Input
    }],
    delay: [{
      type: Input
    }],
    onShown: [{
      type: Output
    }],
    onHidden: [{
      type: Output
    }],
    htmlContent: [{
      type: Input,
      args: ['tooltipHtml']
    }],
    _placement: [{
      type: Input,
      args: ['tooltipPlacement']
    }],
    _isOpen: [{
      type: Input,
      args: ['tooltipIsOpen']
    }],
    _enable: [{
      type: Input,
      args: ['tooltipEnable']
    }],
    _appendToBody: [{
      type: Input,
      args: ['tooltipAppendToBody']
    }],
    tooltipAnimation: [{
      type: Input
    }],
    _popupClass: [{
      type: Input,
      args: ['tooltipClass']
    }],
    _tooltipContext: [{
      type: Input,
      args: ['tooltipContext']
    }],
    _tooltipPopupDelay: [{
      type: Input,
      args: ['tooltipPopupDelay']
    }],
    tooltipFadeDuration: [{
      type: Input
    }],
    _tooltipTrigger: [{
      type: Input,
      args: ['tooltipTrigger']
    }],
    tooltipStateChanged: [{
      type: Output
    }]
  }
});
class TooltipModule {
  // @deprecated method not required anymore, will be deleted in v19.0.0
  static forRoot() {
    return {
      ngModule: TooltipModule,
      providers: []
    };
  }
  static {
    this.ɵfac = i0.ɵɵngDeclareFactory({
      minVersion: "12.0.0",
      version: "19.0.1",
      ngImport: i0,
      type: TooltipModule,
      deps: [],
      target: i0.ɵɵFactoryTarget.NgModule
    });
  }
  static {
    this.ɵmod = i0.ɵɵngDeclareNgModule({
      minVersion: "14.0.0",
      version: "19.0.1",
      ngImport: i0,
      type: TooltipModule,
      imports: [CommonModule, TooltipDirective, TooltipContainerComponent],
      exports: [TooltipDirective]
    });
  }
  static {
    this.ɵinj = i0.ɵɵngDeclareInjector({
      minVersion: "12.0.0",
      version: "19.0.1",
      ngImport: i0,
      type: TooltipModule,
      imports: [CommonModule]
    });
  }
}
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "19.0.1",
  ngImport: i0,
  type: TooltipModule,
  decorators: [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TooltipDirective, TooltipContainerComponent],
      exports: [TooltipDirective]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { TooltipConfig, TooltipContainerComponent, TooltipDirective, TooltipModule };
